import {generatePath} from 'react-router-dom';
import {Entries} from 'type-fest';
import {compact, isString} from 'lodash/fp';

import {
  CarrierLevel,
  CompanyViewType,
  IndustryLevel,
  IntermediaryLevel,
  ProductLevel,
} from 'modules/decisionsNext/api/types';
import {CustomReportType} from 'modules/decisionsNext/pages/ReportsCustomReportPage/reportBuilder/types';
import {CustomReportMode} from 'modules/decisionsNext/types';
import {SearchByType} from 'modules/growth/pages/OpportunitiesNetNewPage/types';

import {BANDING_LIVES, BANDING_PREMIUM, BANDING_REVENUE} from 'core/constants/globalVariables';

export type EntityLevel = IndustryLevel | ProductLevel | CompanyViewType;

export const ENNABL_IMPERSONATE = '/impersonate';
export const IMPERSONATION = 'impersonation';

export const PDF_REPORTS = 'pdf-reports';
export const PDF_REPORTS_CARRIER_V1 = 'carrier/v1';
export const MOCK_DATA = 'mock-data';
// Path for the latest version of carrier PDF report. Change this path to the latest version when it's available
export const CURRENT_REPORT_PATH = `${PDF_REPORTS}/${PDF_REPORTS_CARRIER_V1}`;

export const ENNABL_COMMAND = '/command';
export const COMMAND_STATUS = `${ENNABL_COMMAND}/status`;
export const COMMAND_USERS = `${ENNABL_COMMAND}/users`;
export const COMMAND_INDUSTRIES = `${ENNABL_COMMAND}/industries`;
export const COMMAND_ALERTS = `${ENNABL_COMMAND}/alerts`;
export const COMMAND_VALIDATION = `${ENNABL_COMMAND}/validation`;
export const COMMAND_GEOGRAPHY = `${ENNABL_COMMAND}/business-organization`;

export const COMMAND_STATUS_BROKERAGE = `${COMMAND_STATUS}/brokerage`;

export const COMMAND_BANDS = `${ENNABL_COMMAND}/bandings`;
export const COMMAND_BANDS_REVENUE = `${COMMAND_BANDS}?banding=${BANDING_REVENUE}`;
export const COMMAND_BANDS_PREMIUM = `${COMMAND_BANDS}?banding=${BANDING_PREMIUM}`;
export const COMMAND_BANDS_LIVES = `${COMMAND_BANDS}?banding=${BANDING_LIVES}`;

export const COMMAND_TRANSACTION = `${ENNABL_COMMAND}/transaction`;

export const COMMAND_CLIENT_MAPPING = `${ENNABL_COMMAND}/client`;

export const COMMAND_DATA_ACCESS = `${ENNABL_COMMAND}/data-access`;
export const COMMAND_DATA_ACCESS_CONNECT = `${COMMAND_DATA_ACCESS}/connect`;

export const COMMAND_HISTORY = `${ENNABL_COMMAND}/history`;

export const COMMAND_CARRIER = {
  absolute: `${ENNABL_COMMAND}/market`,
  relative: 'market',
};
export const COMMAND_CARRIER_MAPPING = {
  absolute: `${COMMAND_CARRIER.absolute}/mapping`,
  relative: 'mapping',
};
export const COMMAND_CARRIER_MAPPED = {
  absolute: `${COMMAND_CARRIER.absolute}/mapped`,
  relative: 'mapped',
};

export const COMMAND_PRODUCT = {
  absolute: `${ENNABL_COMMAND}/product`,
  relative: 'product',
};
export const COMMAND_PRODUCT_MAPPING = {
  absolute: `${COMMAND_PRODUCT.absolute}/mapping`,
  relative: 'mapping-new',
};
export const COMMAND_PRODUCT_PRODUCT_TREE = {
  absolute: `${COMMAND_PRODUCT.absolute}/product-tree`,
  relative: 'mapped',
};

export const ENNABL_DECISIONS = '/decisions';

export const DECISIONS_PAGES = `${ENNABL_DECISIONS}/pages`;
export const DECISIONS_PAGES_RECEIVABLES = `${DECISIONS_PAGES}/receivables`;
export const CLIENTS_AND_POLICIES = 'clients-and-policies';
export const DECISIONS_PAGES_CLIENTS_AND_POLICIES = `${DECISIONS_PAGES}/${CLIENTS_AND_POLICIES}`;
export const EXECUTIVE_OVERVIEW = 'executive-overview';
export const KPI = 'kpi';
export const DECISIONS_EXECUTIVE_OVERVIEW = `${ENNABL_DECISIONS}/executive-overview`;
export const ACCOUNT_MANAGER = 'account-manager';
export const ACCOUNT_MANAGERS = 'account-managers';
export const ACCOUNTS = 'accounts';
export const ACCOUNT = 'account';
export const ALERTS = 'alerts';
export const PROSPECTS = 'prospects';
export const PROSPECT = 'prospect';
export const AVERAGE_ACCOUNT_SIZE = 'avg-account-size';
export const PREMIUM_SIZE = 'premium-size';
export const AVERAGE_PER_CLIENT = 'avg-per-client';
export const AVERAGE_PRODUCTS_PER_CLIENT = 'avg-products-per-client';
export const EMPLOYEES_RELATIONSHIPS = 'employees-relationships';
export const TOP_PRODUCTS = 'top-products';
export const TOP_INDUSTRIES = 'top-industries';
export const TOP_CARRIERS = 'top-carriers';
export const BY_PRODUCTS = 'by-products';
export const BY_PRODUCT = 'by-product';
export const BY_INDUSTRIES = 'by-industries';
export const BY_CARRIER = 'by-carrier';
export const CARRIER_APPETITE = 'carrier-appetite';
export const CLIENT_SIZE = 'client-size';
export const POLICIES = 'policies';
export const RETENTION = 'retention';
export const PREMIUM_FLOW = 'premium-flow';
export const LEADERBOARD = 'leaderboard';
export const GROWTH = 'growth';
export const OPPORTUNITIES = 'opportunities';
export const MARKETS = 'markets';
export const DECISIONS_PAGES_OPPORTUNITIES = `${DECISIONS_PAGES}/${OPPORTUNITIES}`;
export const OPPORTUNITY_FINDER = 'opportunity-finder';
export const DECISIONS_PAGES_OPPORTUNITIES_OPPORTUNITIES_FINDER = `${DECISIONS_PAGES}/${OPPORTUNITIES}/${OPPORTUNITY_FINDER}`;
export const REPORTS = 'reports';
export const DOCUMENTS = 'documents';
export const PRODUCER = 'producer';
export const PRODUCERS = 'producers';
export const SEARCH = `search`;
export const PRODUCT = 'product';
export const PRODUCT_GROUP = 'product-group';
export const PRODUCT_SUPER_GROUP = 'product-super-group';
export const PRODUCT_INSURANCE_SEGMENT = 'product-insurance-segment';
export const PRODUCTS = 'products';

export const O360SCORE = 'o360score';
export const INDUSTRIES = 'industries';
export const INDUSTRY = 'industry';
export const INDUSTRY_GROUP = 'industry-group';
export const INDUSTRY_SUBGROUP = 'industry-subgroup';
export const SHARE_FILTERS = 'share-filters';
export const LOSS_RUNS = 'loss-runs';
export const REVEAL = 'reveal';

export const DECISIONS_PAGES_NEW_BUSINESS_PRODUCERS = `${ENNABL_DECISIONS}/new-business-producers`;
export const DECISIONS_PAGES_NEW_BUSINESS_PRODUCER = `${ENNABL_DECISIONS}/new-business-producer`;
export const RENEWALS = 'renewals';
export const DECISIONS_PAGES_RENEWALS = `${DECISIONS_PAGES}/${RENEWALS}`;
export const DECISIONS_PAGES_ACCOUNT_MANAGERS_RENEWALS = `${ENNABL_DECISIONS}/${ACCOUNT_MANAGERS}/${RENEWALS}`;
export const DECISIONS_PAGES_ACCOUNT_MANAGERS_ACCOUNT_MANAGERS = `${ENNABL_DECISIONS}/${ACCOUNT_MANAGERS}/${ACCOUNT_MANAGERS}`;
export const DECISIONS_REPORTS = `${ENNABL_DECISIONS}/${REPORTS}`;
export const DECISIONS_DOCUMENTS = `${ENNABL_DECISIONS}/${DOCUMENTS}`;
export const DECISIONS_KPI = `${ENNABL_DECISIONS}/${KPI}`;
export const CUSTOM_REPORT = 'custom-report';
export const DECISIONS_REPORTS_CUSTOM_REPORT = `${DECISIONS_REPORTS}/${CUSTOM_REPORT}`;

export const DECISIONS_CARRIER_APPETITE = `${ENNABL_DECISIONS}/${CARRIER_APPETITE}`;
export const DECISIONS_PRODUCERS = `${ENNABL_DECISIONS}/${PRODUCERS}`;
export const DECISIONS_PRODUCER = `${ENNABL_DECISIONS}/${PRODUCER}`;
export const DECISIONS_ACCOUNTS = `${ENNABL_DECISIONS}/${ACCOUNTS}`;
export const DECISIONS_PROSPECTS = `${ENNABL_DECISIONS}/${PROSPECTS}`;
export const DECISIONS_ACCOUNTS_ACCOUNTS = `${ENNABL_DECISIONS}/${ACCOUNTS}/${ACCOUNTS}`;
export const DECISIONS_ACCOUNTS_LOST = `${ENNABL_DECISIONS}/${ACCOUNTS}/lost`;
export const DECISIONS_ACCOUNT_MANAGER = `${ENNABL_DECISIONS}/${ACCOUNT_MANAGER}`;
export const DECISIONS_MARKETS = `${ENNABL_DECISIONS}/markets`;
export const DECISIONS_ACCOUNT_MANAGERS = `${ENNABL_DECISIONS}/${ACCOUNT_MANAGERS}`;
export const DECISIONS_INDUSTRIES = `${ENNABL_DECISIONS}/${INDUSTRIES}`;
export const DECISIONS_INDUSTRY = `${ENNABL_DECISIONS}/${INDUSTRY}`;
export const DECISIONS_INDUSTRY_GROUP = `${ENNABL_DECISIONS}/${INDUSTRY_GROUP}`;
export const DECISIONS_INDUSTRY_SUBGROUP = `${ENNABL_DECISIONS}/${INDUSTRY_SUBGROUP}`;
export const DECISIONS_PRODUCT = `${ENNABL_DECISIONS}/${PRODUCT}`;
export const DECISIONS_PRODUCTS = `${ENNABL_DECISIONS}/products`;
export const DECISIONS_PRODUCT_GROUP = `${ENNABL_DECISIONS}/product-group`;
export const DECISIONS_PRODUCT_SUPER_GROUP = `${ENNABL_DECISIONS}/product-super-group`;
export const DECISIONS_PRODUCT_INSURANCE_SEGMENT = `${ENNABL_DECISIONS}/${PRODUCT_INSURANCE_SEGMENT}`;
export const DECISIONS_CLIENT = `${ENNABL_DECISIONS}/client`;
export const DECISIONS_PROSPECT = `${ENNABL_DECISIONS}/${PROSPECT}`;
export const DECISIONS_ACCOUNT = `${ENNABL_DECISIONS}/${ACCOUNT}`;
export const DECISIONS_O360SCORE = `${ENNABL_DECISIONS}/${O360SCORE}`;
export const DECISIONS_O360SCORE_PRODUCER_URL = `${ENNABL_DECISIONS}/${O360SCORE}/:id`;
export const DECISIONS_LOSS_RUNS = `${ENNABL_DECISIONS}/${LOSS_RUNS}`;

export const DECISIONS_PRODUCER_URL = `${DECISIONS_PRODUCER}/:id`;
export const DECISIONS_ACCOUNT_MANAGER_URL = `${DECISIONS_ACCOUNT_MANAGER}/:id`;
export const DECISIONS_PRODUCT_URL = `${DECISIONS_PRODUCT}/:id`;
export const DECISIONS_PRODUCT_GROUP_URL = `${DECISIONS_PRODUCT_GROUP}/:id`;
export const DECISIONS_PRODUCT_SUPER_GROUP_URL = `${DECISIONS_PRODUCT_SUPER_GROUP}/:id`;
export const DECISIONS_PRODUCT_INSURANCE_SEGMENT_URL = `${DECISIONS_PRODUCT_INSURANCE_SEGMENT}/:id`;
export const DECISIONS_INDUSTRY_SUBGROUP_URL = `${DECISIONS_INDUSTRY_SUBGROUP}/:id`;
export const DECISIONS_INDUSTRY_GROUP_URL = `${DECISIONS_INDUSTRY_GROUP}/:id`;
export const DECISIONS_INDUSTRY_URL = `${DECISIONS_INDUSTRY}/:id`;
export const DECISIONS_PROSPECT_URL = `${DECISIONS_PROSPECT}/:id`;
export const DECISIONS_ACCOUNT_URL = `${DECISIONS_ACCOUNT}/:id`;
export const DECISIONS_ACCOUNT_ALERTS_URL = `${DECISIONS_ACCOUNT}/:id/${ALERTS}`;

export const DECISIONS_ACCOUNT_MANAGERS_TOP_CARRIERS = `${ENNABL_DECISIONS}/${ACCOUNT_MANAGERS}/${TOP_CARRIERS}`;
export const DECISIONS_ACCOUNT_MANAGERS_TOP_INDUSTRIES = `${ENNABL_DECISIONS}/${ACCOUNT_MANAGERS}/${TOP_INDUSTRIES}`;
export const DECISIONS_ACCOUNT_MANAGERS_TOP_PRODUCTS = `${ENNABL_DECISIONS}/${ACCOUNT_MANAGERS}/${TOP_PRODUCTS}`;
export const DECISIONS_SHARE_FILTERS = `${ENNABL_DECISIONS}/${SHARE_FILTERS}/:id`;
export const DECISIONS_RETENTION = `${ENNABL_DECISIONS}/${RETENTION}`;

export const GOALS = 'goals';
const LISTS = 'lists';
const REMINDERS = 'reminders';

export const ENNABL_GROWTH = '/growth';
export const MY_GROWTH_ID = 'my';
export const GROWTH_OPPORTUNITIES = `${ENNABL_GROWTH}/${MY_GROWTH_ID}/${OPPORTUNITIES}`;
export const GROWTH_LISTS = `${ENNABL_GROWTH}/${MY_GROWTH_ID}/${LISTS}`;
export const GROWTH_REMINDERS = `${ENNABL_GROWTH}/${MY_GROWTH_ID}/${REMINDERS}`;
export const GROWTH_GOALS = `${ENNABL_GROWTH}/${MY_GROWTH_ID}/${GOALS}`;

export const ENNABL_CHAT_AI = '/chat-ai';

const standalonePagePaths = {
  documents: DECISIONS_DOCUMENTS,
  'all-markets': DECISIONS_MARKETS,
  'all-account-managers': DECISIONS_ACCOUNT_MANAGERS,
  'all-producers': DECISIONS_PRODUCERS,
  'all-products': DECISIONS_PRODUCTS,
  'all-accounts': DECISIONS_ACCOUNTS,
  'all-industries': DECISIONS_INDUSTRIES,
  'executive-overview': DECISIONS_EXECUTIVE_OVERVIEW,
  'accounts-receivable': DECISIONS_PAGES_RECEIVABLES,
  'clients-and-policies': DECISIONS_PAGES_CLIENTS_AND_POLICIES,
  'producer-goals': DECISIONS_PAGES_NEW_BUSINESS_PRODUCERS,
  renewals: DECISIONS_PAGES_RENEWALS,
  reports: DECISIONS_REPORTS,
  growth: ENNABL_GROWTH,
  opportunities: DECISIONS_PAGES_OPPORTUNITIES,
  'loss-runs': DECISIONS_LOSS_RUNS,
  retention: DECISIONS_RETENTION,
} as const;

export const nonMobileAdaptivePages: Array<keyof typeof standalonePagePaths> = ['executive-overview', 'opportunities'];

type ComplexPathUrlProps = {
  url: string;
};
type ComplexPathTabsProps = {
  tab?: string | null;
  subTab?: string | null;
};
type ComplexPathIdProps = {
  id: string;
};
type ComplexPathSearchParamsProps = {
  searchParams?: {key: string; value?: string}[];
};
type MarketTypeProps = {
  type?: MarketType;
};

export const MARKET = 'market';

export const DECISIONS_MARKET_URL_BASE = `${ENNABL_DECISIONS}/${MARKET}/:category/:id`;
const DECISIONS_MARKET_URL = `${DECISIONS_MARKET_URL_BASE}/:type`;

export enum MarketCategory {
  ULTIMATE = 'ultimate',
  PARENT = 'parent',
  CHILD = 'child',
}

export enum MarketType {
  ICO = 'ico',
  BCO = 'bco',
  INTERMEDIARY = 'intermediary',
}

export function generateDecisionsMarketPath(params: {id: string; category: MarketCategory; type: MarketType}) {
  return generatePath(DECISIONS_MARKET_URL, {
    ...params,
    id: encodeURIComponent(params.id),
  });
}

export function generateDecisionsUltimateIntermediaryPath(pathProps: ComplexPathIdProps) {
  return generateDecisionsMarketPath({
    id: pathProps.id,
    category: MarketCategory.ULTIMATE,
    type: MarketType.INTERMEDIARY,
  });
}

export function generateDecisionsParentIntermediaryPath(pathProps: ComplexPathIdProps) {
  return generateDecisionsMarketPath({
    id: pathProps.id,
    category: MarketCategory.PARENT,
    type: MarketType.INTERMEDIARY,
  });
}

export function generateDecisionsIntermediaryPath(pathProps: ComplexPathIdProps) {
  return generateDecisionsMarketPath({
    id: pathProps.id,
    category: MarketCategory.CHILD,
    type: MarketType.INTERMEDIARY,
  });
}

export function generateDecisionsUltimateCarrierPath(pathProps: ComplexPathIdProps & MarketTypeProps) {
  return generateDecisionsMarketPath({
    id: pathProps.id,
    category: MarketCategory.ULTIMATE,
    type: pathProps.type ?? MarketType.ICO,
  });
}

export function generateDecisionsParentCarrierPath(pathProps: ComplexPathIdProps & MarketTypeProps) {
  return generateDecisionsMarketPath({
    id: pathProps.id,
    category: MarketCategory.PARENT,
    type: pathProps.type ?? MarketType.ICO,
  });
}

export function generateDecisionsIssuingPaperCarrierPath(pathProps: ComplexPathIdProps & MarketTypeProps) {
  return generateDecisionsMarketPath({
    id: pathProps.id,
    category: MarketCategory.CHILD,
    type: pathProps.type ?? MarketType.ICO,
  });
}

type GeneratePathProps = ComplexPathTabsProps & ComplexPathIdProps & ComplexPathUrlProps & ComplexPathSearchParamsProps;
export type DecisionsPathProps = ComplexPathTabsProps & ComplexPathIdProps & ComplexPathSearchParamsProps;

const generateComplexPath = ({
  url,
  tab,
  subTab,
  searchParams,
}: ComplexPathTabsProps & ComplexPathUrlProps & ComplexPathSearchParamsProps) => {
  if (searchParams) {
    return `${url}?${searchParams
      .filter(({value}) => value)
      .map(({key, value}) => `${key}=${value}`)
      .join('&')}`;
  }

  const complexUrl = [url, tab].filter(isString).join('/');
  return subTab ? `${complexUrl}?tab=${subTab}` : complexUrl;
};

const generateDecisionsPath = ({url, id, ...pathProps}: GeneratePathProps) => {
  return generateComplexPath({url: generatePath(url, {id: encodeURIComponent(id)}), ...pathProps});
};

export function generateStandalonePagePath({id, ...pathProps}: DecisionsPathProps) {
  return generateComplexPath({...pathProps, url: getStandalonePagePath(id as keyof typeof standalonePagePaths)});
}

/**
 * @deprecated
 * Try to avoid to use this function directly if you are not sure
 * what you are doing. Instead, try to use 'modules/decisions/hooks/useDecisionsProducerPath'
 * hook, because it's do additional restrictions for producers
 */
export function generateDecisionsProducerPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_PRODUCER_URL});
}

export function generateDecisionsAccountManagerPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_ACCOUNT_MANAGER_URL});
}

export function generateDecisionsProductPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_PRODUCT_URL});
}

export function generateDecisionsProductGroupPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_PRODUCT_GROUP_URL});
}

export function generateDecisionsProductSuperGroupPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_PRODUCT_SUPER_GROUP_URL});
}

export function generateDecisionsProductInsuranceSegmentPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_PRODUCT_INSURANCE_SEGMENT_URL});
}

export function generateDecisionsIndustryPathByLevel(pathProps: DecisionsPathProps & {level: IndustryLevel}) {
  return industryPathGenByLevel[pathProps.level](pathProps);
}

export function generateDecisionsIndustryPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_INDUSTRY_URL});
}

export function generateDecisionsIndustryGroupPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_INDUSTRY_GROUP_URL});
}

export function generateDecisionsIndustrySubGroupPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_INDUSTRY_SUBGROUP_URL});
}

export function generateDecisionsO360ProducerScorePath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_O360SCORE_PRODUCER_URL});
}

export function generateDecisionsAccountPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_ACCOUNT_URL});
}

export function generateDecisionsAccountAlertsPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_ACCOUNT_ALERTS_URL});
}

export type DecisionsPathTypes =
  | 'client'
  | 'market'
  | 'intermediary'
  | 'company'
  | 'product'
  | 'producer'
  | 'industry'
  | 'prospect';

export function generateDecisionsPathByType(
  type: DecisionsPathTypes,
  pathProps: DecisionsPathProps,
  level?: EntityLevel
) {
  switch (type) {
    case 'client':
      return generateDecisionsAccountPath(pathProps);

    case 'industry': {
      if (!level) {
        return '#';
      }

      return industryPathGenByLevel[level as IndustryLevel](pathProps);
    }

    case 'market': {
      if (!level) {
        return '#';
      }

      return carrierPathGenByType[
        level as CompanyViewType.UltimateParentCarrier | CompanyViewType.ParentCarrier | CompanyViewType.IssuingPaper
      ](pathProps);
    }

    case 'intermediary': {
      if (!level) {
        return '#';
      }

      return intermediaryPathGenByType[
        level as
          | CompanyViewType.UltimateParentIntermediary
          | CompanyViewType.ParentIntermediary
          | CompanyViewType.Intermediary
      ](pathProps);
    }

    case 'company':
      if (!level) {
        return '#';
      }

      return companyPathGenByType[level as CompanyViewType](pathProps);
    case 'product':
      return generateDecisionsProductPath(pathProps);
    case 'producer':
      return generateDecisionsProducerPath(pathProps);
    case 'prospect':
      return generateDecisionsProducerPath(pathProps);
    default:
      throw new Error(`Unknown decisions path type: ${type}`);
  }
}

/**
 * Construct a link to industry page.
 *
 * This is a low-level map, and it shouldn't be used directly. Use {@link generateDecisionsIndustryPathByLevel} instead
 *
 * @deprecated this is NOT a deprecation warning. This is a warning that you probably don't
 * want to use this map directly
 */
export const industryPathGenByLevel = {
  [IndustryLevel.Group]: generateDecisionsIndustryGroupPath,
  [IndustryLevel.SubGroup]: generateDecisionsIndustrySubGroupPath,
  [IndustryLevel.Industry]: generateDecisionsIndustryPath,
};

export const intermediaryPathGenByType = {
  [CompanyViewType.UltimateParentIntermediary]: generateDecisionsUltimateIntermediaryPath,
  [CompanyViewType.ParentIntermediary]: generateDecisionsParentIntermediaryPath,
  [CompanyViewType.Intermediary]: generateDecisionsIntermediaryPath,
};

export const carrierPathGenByType = {
  [CompanyViewType.UltimateParentCarrier]: generateDecisionsUltimateCarrierPath,
  [CompanyViewType.ParentCarrier]: generateDecisionsParentCarrierPath,
  [CompanyViewType.IssuingPaper]: generateDecisionsIssuingPaperCarrierPath,
};

export const companyPathGenByType = {
  ...intermediaryPathGenByType,
  ...carrierPathGenByType,
};

export const carrierLevelToCompanyView = {
  [CarrierLevel.UltimateParentCarrier]: CompanyViewType.UltimateParentCarrier,
  [CarrierLevel.ParentCarrier]: CompanyViewType.ParentCarrier,
  [CarrierLevel.IssuingPaper]: CompanyViewType.IssuingPaper,
};

export const intermediaryLevelToCompanyView = {
  [IntermediaryLevel.UltimateParentIntermediary]: CompanyViewType.UltimateParentIntermediary,
  [IntermediaryLevel.ParentIntermediary]: CompanyViewType.ParentIntermediary,
  [IntermediaryLevel.ChildIntermediary]: CompanyViewType.Intermediary,
};

export const getCarrierPathGenByLevel = (availableViewModes?: CompanyViewType[], carrierLevel?: CarrierLevel) => {
  const level =
    carrierLevel && availableViewModes?.includes(carrierLevelToCompanyView[carrierLevel])
      ? carrierLevelToCompanyView[carrierLevel]
      : availableViewModes?.[0];

  return level ? companyPathGenByType[level] : undefined;
};

export const getStandalonePagePath = (pageId: keyof typeof standalonePagePaths) => {
  if (!standalonePagePaths[pageId]) {
    return ENNABL_DECISIONS;
  }

  return standalonePagePaths[pageId];
};

export function generateOpportunitiesCompanySearchPath({
  companyName,
  searchBy,
  industries,
  state,
  country,
  county,
}: {
  companyName?: string;
  searchBy?: SearchByType;
  industries?: string;
  state?: string;
  country?: string;
  county?: string;
} = {}) {
  const searchParams = new URLSearchParams();
  searchParams.append('tab', 'company-search');

  if (companyName) {
    searchParams.append('companyName', companyName);
  }

  if (searchBy) {
    searchParams.append('searchBy', searchBy);
  }

  if (industries) {
    searchParams.append('industries', industries);
  }

  if (country) {
    searchParams.append('country', country);
  }

  if (state) {
    searchParams.append('state', state);
  }

  if (county) {
    searchParams.append('county', county);
  }

  return `${DECISIONS_PAGES_OPPORTUNITIES}/${OPPORTUNITY_FINDER}?${searchParams.toString()}`;
}

type PeopleSearchPathParams = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  state?: string;
};

export function generateOpportunitiesPeopleSearchPath(params: PeopleSearchPathParams) {
  const psParams = {} as Record<string, string>;

  for (const [key, value] of Object.entries(params) as Entries<PeopleSearchPathParams>) {
    if (value !== undefined) {
      psParams[`ps_${key}`] = value;
    }
  }

  const searchParams = new URLSearchParams(psParams);
  searchParams.append('tab', 'people-search');
  return `${DECISIONS_PAGES_OPPORTUNITIES}/${OPPORTUNITY_FINDER}?${searchParams.toString()}`;
}

export function generateDecisionsProspectPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_PROSPECT_URL});
}

export function generateDecisionsCustomReportPath({
  reportType,
  mode,
  ...pathProps
}: DecisionsPathProps & {
  reportType: CustomReportType;
  mode?: CustomReportMode;
}) {
  return generateDecisionsPath({
    ...pathProps,
    url: `${DECISIONS_REPORTS}/${CUSTOM_REPORT}/:id`,
    searchParams: compact([{key: 'type', value: reportType}, mode && {key: 'mode', value: mode}]),
  });
}

export function generateDecisionsShareFiltersPath(pathProps: DecisionsPathProps) {
  return generateDecisionsPath({...pathProps, url: DECISIONS_SHARE_FILTERS});
}
