import {useCallback} from 'react';
import {toast} from 'sonner';

import * as Types from 'modules/decisionsNext/api/types';
import {UserReportStatus} from 'modules/decisionsNext/api/types';

import {urlDownload} from 'utils/misc';
import {addDownloadJob} from 'core/stores/downloadReports';

type FileToDownload = {
  id: string;
  url?: string | null;
  status: Types.UserReportStatus;
  filename: string;
};

export function useDownloadFile(defaultFilename: string, title?: string) {
  const downloadFile = useCallback(
    (file: FileToDownload) => {
      const {id, url, filename, status} = file;

      if (url && status === UserReportStatus.Ready) {
        urlDownload(url, filename || defaultFilename);
      } else if (id) {
        addDownloadJob({
          id,
          status,
          filename,
          title,
        });
      } else {
        toast.error(`Unable to generate ${title || 'Report'}`);
      }
    },
    [title, defaultFilename]
  );

  return {
    downloadFile,
  };
}
