import {useEffect} from 'react';
import LogRocket from 'logrocket';
import {useRouteError} from 'react-router-dom';
import {isError} from 'lodash';

import {showUpdateNotification} from 'utils/appVersionUtils';
import {isWebViewApp} from 'core/utils/webView';
import {NoPermissionError, NotFoundError} from 'core/utils/errors';

import {SomethingWentWrongPage} from 'components/Errors/SomethingWentWrongPage';
import {NoPermissionPage} from 'components/Errors/NoPermissionPage';
import NotFoundPage from 'components/Errors/NotFoundPage';

export const RouterErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    if (!isError(error)) {
      return;
    }

    if (!(error instanceof NotFoundError)) {
      LogRocket.captureException(error);
    }

    if (isWebViewApp()) {
      console.error('WebView error', error);
    }

    if ('ChunkLoadError' === error?.name) {
      showUpdateNotification(true);
    }
  }, [error]);

  if (error instanceof NoPermissionError) {
    return <NoPermissionPage />;
  }

  if (error instanceof NotFoundError) {
    return <NotFoundPage />;
  }

  return <SomethingWentWrongPage />;
};
