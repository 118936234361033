import {colorVariables} from 'core/constants';

export type DesignTokenGraph = keyof typeof colorVariables.Graph;

export type FeatureFlag = {
  name: string;
  description?: string;
  enabled: boolean;
  enabledOnDev?: boolean;
  meta?: any;
  activeForTenants?: string[];
  inactiveForTenants?: string[];
};

export enum LocalStorageKey {
  AIWelcome = 'ai_welcome',
  CarrierAppetiteStore = 'carrier_appetite_store',
  ChatsStore = 'chats_store',
  CypressToken = 'auth0_token_cypress',
  CypressExpiresIn = 'auth0_expires_in_cypress',
  CustomLimitValue = 'custom_limit_value',
  DecisionsRecentFiltersByTenant = 'decisions_recent_filters_by_tenant',
  DecisionsRecentQueries = 'decisions_recent_queries',
  DecisionsSavedFiltersByTenant = 'decisions_saved_filters_by_tenant',
  DocumentsStore = 'documents_store',
  DownloadMobileAppLastDialogTime = 'download_mobile_app_last_dialog_time',
  EmulateLimitError = 'emulate_limit_error',
  EnnablRdeApiHost = 'ennabl_rde_api_host',
  GrowthSidebarListsShowMore = 'growth_sidebar_lists_show_more',
  IsNetNewOpportunitiesSearchLimitExceededModalHidden = 'is_net_new_opportunities_search_limit_exceeded_modal_hidden',
  IsNetNewOpportunitiesSearchLimitsWarningHidden = 'is_net_new_opportunities_search_limits_warning_hidden',
  KpiCatalogSearchRecentSearchItems = 'kpi_catalog_search_recent_search_items',
  KpiPagePremiumTypeFilter = 'kpi_page_premium_type_filter',
  LossRunsListSort = 'loss_runs_list_sort',
  MarketsCarrierLevelFilterStore = 'markets_carrier_level_filter_store',
  Modifiers = 'modifiers',
  MonolineHighlights = 'monoline_highlights',
  O360Score = 'o360_score',
  O360UnisonBook = 'o360_unison_book',
  OpportunitiesCompanyForm5500SearchRecentSearchItems = 'opportunities_company_form5500_search_recent_search_items',
  OpportunitiesCompanySearchByIndustryCountryCode = 'opportunities_company_search_by_industry_country_code',
  OpportunitiesCompanySearchByIndustryRecentSearchItems = 'opportunities_company_search_by_industry_recent_search_items',
  OpportunitiesCompanySearchByNameCountryCode = 'opportunities_company_search_by_name_country_code',
  OpportunitiesCompanySearchByNameRecentSearchItems = 'opportunities_company_search_by_name_recent_search_items',
  OpportunitiesCrossSellByForm5500RecentSearchItems = 'opportunities_cross-sell_by_form5500_recent_search_items',
  OpportunitiesCrossSellRecentSearchItems = 'opportunities_cross-sell_recent_search_items',
  OpportunitiesLocationSearchRecentSearchItems = 'opportunities_location_search_recent_search_items',
  OpportunitiesPeopleSearchCountryCode = 'opportunities_people_search_country_code',
  OpportunitiesPeopleSearchRecentSearchItems = 'opportunities_people_search_recent_search_items',
  OpportunitiesRenewalsRecentSearchItems = 'opportunities_renewals_recent_search_items',
  OpportunitiesWinBackRecentSearchItems = 'opportunities_win_back_recent_search_items',
  RenewalsPageSavedPeriod = 'renewals_page_saved_period',
  RenewalsSavedPeriodDataBase = 'renewals_saved_period_data_base',
  RetentionFiltersStore = 'retention_filters_store',
  WithDevServiceWorker = 'with_dev_service_worker',
  FavoritesMigrationInfo = 'favorites_migration_info',
}

export type AuthToken = {
  token: string;
  expiresAt: number;
};
